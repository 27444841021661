<!--
 * @Descripttion: 
-->
<!--
 * @Descripttion: 
-->
<!--
 * @Descripttion: 
-->
<template>
    <!-- <div class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
    <div class="title">新建 <img src="@/assets/imgs/close.png" alt="" @click="hideMask"></div> -->
    <div :class="[flag ? '' : 'content-box']">
        <div :class="[flag ? '' : 'container']">
            <div class="content">
                <div>
                    <div class="content_row flex">
                        <label class="info" for="">团队<span class="redS">*</span></label>
                        <div class="content_right">
                            <a-select
                                size="large"
                                v-model="executor"
                                placeholder="请选择执行团队"
                            >
                                <a-select-option
                                    v-for="(item, index) in clientList"
                                    :key="index"
                                    :value="item.did"
                                >
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">目标周期<span class="redS">*</span></label>
                        <div class="content_right">
                            <a-select
                                size="large"
                                v-model="client"
                                placeholder="请选择要添加的目标周期"
                            >
                                <a-select-option
                                    v-for="(item, index) in timeType"
                                    :key="index"
                                    :value="item.key"
                                >
                                    {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">时间<span class="redS">*</span></label>
                        <div class="content_right">
                            <!-- 选择年份 -->
                            <a-select
                                v-if="client == 1"
                                v-model="yearSelect"
                                aria-placeholder="请选择年份"
                            >
                                <a-select-option
                                    v-for="(item, index) in yearArr"
                                    :key="index"
                                    :value="item"
                                >
                                    {{ item }}
                                </a-select-option>
                            </a-select>

                            <!-- 选择月份 -->
                            <a-month-picker
                                v-if="client == 3"
                                v-model="monthSelect"
                                class="inpWidth"
                                placeholder="请选择月份"
                            >
                            </a-month-picker>
                            <!-- 选择周 -->
                            <!-- <a-input @focus="blur" :value="endOpen + ''"></a-input> -->
                            <a-week-picker
                                style="width: 400px"
                                v-if="client == 4"
                                @change="weekChange"
                                class="inpWidth"
                                placeholder="请选择周"
                                v-model="createWeek"
                            >
                                <span>
                                    <a-input v-model="createText"></a-input>
                                </span>
                            </a-week-picker>

                            <!-- 选择季度 -->
                            <div v-if="client == 2" class="inpWidth dis-bwe">
                                <a-select
                                    style="width: 180px"
                                    v-model="timeSelect"
                                    aria-placeholder="请选择年份"
                                >
                                    <a-select-option
                                        v-for="(item, index) in yearArr"
                                        :key="index"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </a-select-option>
                                </a-select>
                                <a-select
                                    style="width: 180px"
                                    v-model="timeSelect"
                                    aria-placeholder="请选择季度"
                                >
                                    <a-select-option
                                        v-for="(item, index) in [
                                            '第一季度',
                                            '第二季度',
                                            '第三季度',
                                            '第四季度',
                                        ]"
                                        :key="index"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </div>
                    </div>

                    <div class="content_row flex" style="align-items: normal">
                        <label class="info" for="">新客数</label>
                        <div class="content_right">
                            <a-input-number
                                v-model="signlistnums"
                                class="inpWidth"
                                placeholder="请输入新客数"
                            >
                            </a-input-number>
                        </div>
                    </div>

                    <div class="content_row flex" style="align-items: normal">
                        <label class="info" for="">签单数</label>
                        <div class="content_right">
                            <a-input-number
                                v-model="signlistnum"
                                class="inpWidth"
                                placeholder="请输入签单数"
                            ></a-input-number>
                        </div>
                    </div>
                    <div class="content_row flex" style="align-items: normal">
                        <label class="info" for="">签单金额</label>
                        <div class="content_right">
                            <a-input-number
                                v-model="signlistmoney"
                                class="inpWidth"
                                placeholder="请输入签单金额"
                            ></a-input-number>
                        </div>
                    </div>
                    <div class="content_row flex" style="align-items: normal">
                        <label class="info" for="">回款数</label>
                        <div class="content_right">
                            <a-input-number
                                v-model="paymentnum"
                                class="inpWidth"
                                placeholder="请输入回款数"
                            ></a-input-number>
                        </div>
                    </div>
                    <div class="content_row flex" style="align-items: normal">
                        <label class="info" for="">回款金额</label>
                        <div class="content_right">
                            <a-input-number
                                v-model="paymentmoney"
                                class="inpWidth"
                                placeholder="请输入回款金额"
                            ></a-input-number>
                        </div>
                    </div>

                    <div class="save" v-if="isedit != 1">
                        <a-button type="primary" @click="submit">保存</a-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import { requestXml, isMobile } from "../../../assets/js/request";
var moment = require("moment");
let yearArr = [];
var myDate = new Date();
let Y =myDate.getFullYear();
for (let i = Y; i < 2100; i++) {
    yearArr.push(i);
}
export default {
    props: {
        taskId: {
            type: String,
        },
    },
    data() {
        return {
            isedit: 1, //1查看 2 编辑
            createTime: null, //目标创建时间
            endtime: null,
            createText: "",
            executor: undefined, //执行人
            client: 1, //目标类型

            createWeek: null, //周选择 中间变量
            yearSelect: null, //年选择 中间变量
            monthSelect: null, //月选择 中间变量

            yearArr,
            timeSelect: "",
            timeType: [
                { label: "年", key: 1 },
                // { label: "季度", key: 2 },
                { label: "月", key: 3 },
                { label: "周", key: 4 },
            ],
            signlistnums: '', //新客
            signlistnum: 0, //签单
            signlistmoney: 0, //签单金额
            paymentnum: 0, //回款数
            paymentmoney: 0, //回款
            type: 0,
            desc: "",
            date: [],
            start: "",
            end: "",

            clientList: [],

            mode: 1,
            staff_uid: "default",
            handlerList: [],

            // 任务id
            id: "",
            taskList: [],
            flag: true,
        };
    },

    methods: {
        //获取目标详情
        getTaskDetail(id = "", type = "get") {
            let url = "/scrm/Target/teamedit";
            let postdata = {
                id: id,
            };
            let that = this;
            requestXml(
                url,
                type,
                (res) => {
                    that.executor = Number( res.staff_id);
                    // that.timetype = res.timetype;
                    let target = this.timeType.find((item) => {
                        if (item.label == res.timetype) {
                            console.log("item.label", item.label);
                            return item.label;
                        }
                    });
                    if (target?.key) {
                        let client = target.key;
                        that.client = client;
                        if (client == 1) {
                            that.yearSelect = moment(
                                res.createtime * 1000
                            ).year();
                            console.log("现在是年份", that.yearSelect);
                        }
                        if (client == 3) {
                            that.monthSelect = moment(res.createtime * 1000);
                        }
                        if (client == 4) {
                            that.createWeek = moment(res.createtime * 1000);
                            console.log("现在是周", that.createWeek);
                            that.weekChange(that.createWeek, "");
                        }
                    }

                    that.signlistnums = res.newcustomers;
                    that.signlistnum = res.signlistnum;
                    that.signlistmoney = res.signlistmoney;
                    that.paymentnum = res.paymentnum;
                    that.paymentmoney = res.paymentmoney;
                    // that.signlistmoney = res.signlistmoney;
                    // that.signlistmoney = res.signlistmoney;
                    // that.signlistmoney = res.signlistmoney;
                },
                { ...postdata }
            );
        },

        blur() {
            this.endOpen = !this.endOpen;
        },
        handleEndOpenChange(open) {
            this.endOpen = open;
        },
        weekChange(date) {
            console.log("周变化触发入参为", date);
            let start = {
                s: moment(+date).format("YYYY-MM-DD"),
                e: date.format("YYYY-MM-DD"),
            };
            console.log("start", start);
            // const start = moment(date.format("YYYY-MM-DD"));
            function Person(s, e) {
                this.s = moment(s);
                this.e = moment(e);
            }
            let sum = new Person(start.s, start.e);
            let dataS = "";
            let dataE = "";
            dataS = sum.s.day(1);
            dataE = sum.e.day(7);

            this.createTime = +dataS / 1000;
            this.endtime = +dataE / 1000;
            console.log(
                "this.createTime",
                dataS.weekYear(),
                dataS.weeks(),
                this.endtime
            );

            this.createText =
                dataS.format("YYYY-MM-DD") +
                "到" +
                dataE.format("YYYY-MM-DD") +
                "(" +
                dataS.weekYear() +
                "第" +
                dataS.weeks() +
                "周)";
        },
        // 获取 简化成员列表
        getClientList() {
            requestXml("/scrm/Target/department", "POST", (res) => {
                console.log('res',res);
                this.clientList = res;
            });
        },
        // 获取处理人
        // getStaffList() {
        //     requestXml("/scrm/Staff/getInfo", "GET", (res) => {
        //         this.handlerList = res;
        //         // console.log(this.handlerList)
        //     });
        // },

        // 编辑目标
        submit() {
            let target = this.timeType.find((item) => {
                if (item.key == this.client) {
                    console.log("item.label", item.label);
                    return item.label;
                }
            });

            let postdata = {
                // director_id: "RenShengShiChangXiuHang",
                id: this.id,
                newcustomers: this.signlistnums,
                signlistnum: this.signlistnum,
                signlistmoney: this.signlistmoney,
                paymentnum: this.paymentnum,
                paymentmoney: this.paymentmoney,
                timetype: target.label,
                staff_id: this.executor,
                createtime: this.createTime,
                endtime: this.endtime,
            };
            console.log("staff_idstaff_idstaff_id", this.signlistnums);

            var url;
            url = "/scrm/Target/teamedit";

            console.log(isMobile());
            requestXml(
                url,
                "POST",
                (res) => {
                    if(res!= 'error'){
                    this.$message.success("编辑成功");
                    this.$router.push({
                            path: '/scrm_pc/target/group',
                        });
                    }
                    console.log(res);
                },
                { ...postdata }
            );
        },
    },
    mounted: function () {
        this.getClientList();
        // this.getStaffList();
        //测试用
        // this.getTaskDetail(8);
        this.id = this.$route.query.id;
        let type = this.$route.query.type;
        this.isedit = type;
        console.log("typetype", type);
        if (this.id) {
            this.getTaskDetail(this.id);
        }

        if (isMobile()) {
            this.flag = true;
        } else {
            this.flag = false;
        }
    },
    watch: {
        taskId: function (newVal) {
            this.id = newVal;
        },
        yearSelect(val) {
            if (val) {
                this.createTime = moment(val + "-01-01").unix();
                this.endtime = moment(val + 1 + "-01-01").unix();
                console.log("this.endtime", this.createTime, this.endtime);
            }
        },
        monthSelect(val) {
            if (val) {
                const times = moment(val.format("YYYY-MM-DD"));
                this.createTime = times.subtract(times.dates() - 1, "d").unix();
                this.endtime = times.add(1, "M").unix();
                console.log(
                    "this.createTime",
                    val.dates(),
                    this.createTime,
                    this.endtime
                );
            }
        },
    },
};
</script>
<style lang='less' scoped>
@inp_with: 400px;
.content .content_row {
    align-items: center;
    margin: 2rem 0;
}
.inpWidth {
    width: (@inp_with + 2) !important;
}
.dis-bwe {
    display: flex;
    justify-content: space-between;
}
.content .content_row .content_right {
    width: calc(100% - 15rem);
    text-align: left;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #36a3ff;
    border-color: #36a3ff;
}
.content .content_row .info {
    display: inline-block;
    width: 15rem;
    text-align: right;
    padding-right: 1rem;
    color: #202020;
    font-weight: normal;
}
/* 输入框 下拉框 */
.content .content_row input,
.content .content_row .ant-select {
    width: 100%;
    // background-color: #f9f9f9;
}
.content .content_row .ant-select-selection--multiple input {
    width: 0;
}
/* 日期 */
// .content .content_row .ant-calendar-picker {
//     width: 100% !important;
// }
// .content .content_row .ant-calendar-picker input {
//     width: 45% !important;
// }

.save {
    text-align: left;
}
.save .ant-btn {
    width: 100px;
    height: 40px;
    background: #36a3ff;
    border-radius: 4px;
    margin-left: 13rem;
}

@media screen and (min-width: 750px) {
    .content .content_row {
        margin: 15px 0;
        font-size: 16px;
    }
    .content .content_row .content_right {
        width: calc(100% - 120px);
    }
    .content .content_row .info {
        width: 90px;
        padding-right: 10px;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker {
        width: 400px !important;
    }
    // .content .content_row .ant-calendar-picker input {
    //     width: 45%;
    // }
    /* input ,selsct */
    .content .content_row input,
    .content .content_row .ant-select {
        width: 400px;
    }
    /* textarea */
    .content .content_row textarea.ant-input {
        width: 60%;
    }
    .save .ant-btn {
        margin-left: 90px;
    }
}
</style>
